import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const IndexMarketing = lazy(() => import("./index"));

const marketingRoutes = [
  {
    path: "/marketing/index",
    component: IndexMarketing,
    auth: authRoles.sa,
  },
];

export default marketingRoutes;