import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ConsultaAlunosParecerDescritivo = lazy(() =>
  import("./parecerdescritivo/consulta")
);
const ConsultaPlanejamentoProfessor = lazy(() =>
  import("./planejamento/consulta")
);
const CadastroPlanejamentoProfessor = lazy(() =>
  import("./planejamento/cadastro")
);
const ImpressaoPlanejamentos = lazy(() => import("./planejamento/impressao"));
const ConsultaAlunosConsideracoes = lazy(() =>
  import("./consideracao/consulta")
);

const moduloProfessor = [
  {
    path: "/moduloprofessor/parecerdescritivo/consulta",
    component: ConsultaAlunosParecerDescritivo,
    auth: authRoles.professor,
  },
  {
    path: "/moduloprofessor/planejamento/consulta",
    component: ConsultaPlanejamentoProfessor,
    auth: authRoles.professor,
  },
  {
    path: "/moduloprofessor/planejamento/cadastro",
    component: CadastroPlanejamentoProfessor,
    auth: authRoles.professor,
  },
  {
    path: "/moduloprofessor/planejamento/impressao",
    component: ImpressaoPlanejamentos,
    auth: authRoles.professor,
  },
  {
    path: "/moduloprofessor/consideracao/consulta",
    component: ConsultaAlunosConsideracoes,
    auth: authRoles.professor,
  },
];

export default moduloProfessor;
